import '../App.css'
import { useState } from 'react';
import {
	Tabs, SimpleGrid, Card, Text, Image, Container, createStyles, MantineProvider, Center, Title, Button, rem, Divider, useMantineTheme,
	Anchor, Space, Col, Grid, Group, Box, Header, Badge, Accordion
} from '@mantine/core';
//import { Card, CardContent, CardHeader, CardMedia, Grid} from "@mui/material";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import apple from '../Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917.svg';
import android from '../google-play-badge_resize.png';
import Footer from '../components/Footer'
import logo from '../PB001b_ComedySeen_090723_logo_2.jpg';
import transparentlogo from '../comedy_seen_transparent.png'
import twophone from '../Mobile_smartphone_two_final.png'
import laptop from '../laptop_final.png'
import { Link } from 'react-router-dom'
import {IconStars, IconStar, IconStarFilled } from '@tabler/icons-react'

/*
const useStyles = createStyles((theme) => ({
	
	body: {
		backgroundColor: "d9ebf2"

	}})
	)
*/

const useStyles = createStyles((theme) => ({
	header: {
		backgroundColor: "#d9ebf2"

	},

	link: {
		display: 'block',
		lineHeight: 1,
		padding: `${rem(8)} ${rem(12)}`,
		borderRadius: theme.radius.sm,
		textDecoration: 'none',
		color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
		fontSize: theme.fontSizes.sm,
		fontWeight: 500,

		'&:hover': {
			backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],


		}
	},

	inner: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'


	}
}));

interface HeaderActionProps {

	headerlinks: { link: string; label: string; links: { link: string; label: string }[] }[];
}

function Landing() {



	const { classes, cx } = useStyles()








	console.log('in newland')

	const [comedianView, setComedianView] = useState(true)
	const [fanView, setFanView] = useState(false)
	const [sponsorView, setSponsorView] = useState(false)

	const [view, setView] = useState("Comedians")


	const handleViewChange = (value: any) => {
		console.log('ez mil', value.target.value, typeof (value.target.value))


		setView(value.target.value)


	};



	const cards = [
		{ title: "Track your Matches", content: "Track all your matches and measure your performance the more you play!" },
		{ title: "See Player Activity", content: "Follow your friend's activity and track their matches as they post them" },
		{ title: "Rich Analytics", content: "Curious who you are playing the most?  Maybe that club you play the most over time?  Win streak?  We got them all plus more!" },
		{ title: "Mobile App", content: "A native app is available for iOS or Android!  Tap below to download it!" },
		{ title: "Find Friends to Play", content: "Always trying to find people to play near you?  Volley helps connect you to the vast tennis community to match you with the right players." },
		{ title: "Club Info", content: "Looking for a public place to play?  Members can help provide information on where to play near you, whether it's a public court or club!" }
	]

	const fan_cards = [
		{ title: "Discover the comedy scene and get rewarded", content: "Find stand up comedy including indie stand up shows wherever you are located!  Log and track comedians you've seen live! Get rewarded at the end of the year with tickets!" },
		{ title: "Choose and support the top comics on the app", content: "Every year we reward the top comics on the app both globally and locally.  Nothing affects these rankings more than after show favoriting from fans!" },
		{ title: "Prove that you are a true stand up enthusiast", content: "You don't just 'like' crowd work stand up clips on IG.  You are a true stand up comedy fan and supporter because you go to live shows and check-in!" },
	]

	const comedian_cards = [
		{ title: "Get rewarded with $$$", content: "At the end of the year if you place globally or in locally sponsored leaderboards for up and coming comics, you'll get some cold hard cash!  No fee to participate!  Just do what you normally do!" },
		{ title: "Promote your shows", content: "By putting your events on Comedy Seen you are reaching out to the most hardcore stand up fans who want to go see LIVE stand up comedy." },
		{ title: "Find places to perform", content: "Traveling and looking for leads on places to perform?  We believe we have the biggest and most accurate open mic listing available.  Just check out the map or type in your city and voila!" },
		{ title: "Get discovered", content: "The more active you are the more visible you are on the app for fans" },
		{ title: "Coming soon: Sell tickets", content: "Because of our uniquely low-cost structure (solopreneur-bootstrapped-side project) we believe we'll be able to give you the opportunity to sell tickets with much lower fees than Eventbrite and others while still offering discoverability" }
	]


	const sponsor_cards = [
		{ title: "Get seen locally", content: "Sponsor locally and you will be seen by fans only in your area.  Fans who go out, spend money, and have a good time doing so" },
		{ title: "Get seen globally", content: "Sponsor globally and you will be seen by an even larger audience." },
		{ title: "Support the arts", content: "Know that by sponsoring you will be seen as a supporter of the arts.  Comedy Seen is designed to give money to comics!" },
		{ title: "Customize sponsorship", content: "Want to do something more creative with your sponsorship?  Let us know and we will be glad to make it happen!" }
	]

	const headerlinks = [
		{
			"link": "/Blog",
			"label": "Blog",
			"index": "one"
		},
		{
			"link": "/HOF",
			"label": "Hall of Fame",
			"index": "two"
		},
		{
			"link": "/LeaderboardPrizes24",
			"label": "Leaderboard Prizes",
			"index": "three"
		}

	]



	const links = [


		{
			"link": "/About",
			"label": "About"			

		},
		{
			"link": "/Support",
			"label": "Support"
			

		},
		{
			"link": "/Pp",
			"label": "Privacy"
		

		},
		{
			"link": "/Tos",
			"label": "Terms of Use"
			

		},

	]


	const headeritems = headerlinks.map((link) => (

		<Anchor
			color='dimmed'
			key={link.label}
			href={link.link}
			size='md'
		>
			<Link to={link.link}>
			{link.index === 'two' ? 
			<div>
				<Center>
				<IconStar 
					size={15}
					color='orange'
					/>
					<Text className="HOF-header">	
			{link.label}
			</Text>
			<IconStar 
				size={15}
				color='orange'/>
				</Center>
			</div>
				:
				<div>
				{link.label}
				</div>
			}
			
			</Link>

		</Anchor>

	)


	)

	const theme = useMantineTheme()



	return (


		<body>

			<Box pb={120} pt={40} pr={20}>
				<Header
					height={60}
					px='md'

					className={classes.header}



				>


					<Container className={classes.inner} fluid>
					
					<Group></Group>


						<Grid justify='center'>
							<Group spacing='xl'>
								{headeritems}
							</Group>

						</Grid>



						<Grid justify='flex-end'>
							<Button
								color='green'
								size='md'
								component='a'
								href='https://app.getcomedyseen.com/Login'
								type='button'
							>Log in/Sign up</Button>
						</Grid>

					</Container>
				</Header>
			</Box>

<Container>

			<Title ta="center" sx={{ fontSize: "2.8rem" }}> Welcome to a brand new way to </Title>
			<Title ta="center" sx={{ fontFamily: 'Greycliff CF, sans-serif', fontSize: "2.8rem", color: "#a300e0" }}> discover and enjoy </Title>
			<Title ta="center" sx={{ fontSize: "2.8rem" }}>LIVE stand up comedy!</Title>

			</Container>

			<Center>
				<Image src={transparentlogo} fit="contain" maw={750} mx='auto'></Image>


			</Center>

			<Center>
				<Button size='xl'
				color='green'
				component='a'
				href="https://app.getcomedyseen.com/leaderboards"

				type="button">Current Global Leaderboard</Button>


			</Center>
			<Space h='md'/>


			<Container>

				<Center>
					<Grid gutter='sm' gutterMd='xl' gutterSm='sm' grow justify='center'>
						<Grid.Col span={2}>
							<Button size='xl'
								color="grape"
								component='a'
								href="https://app.getcomedyseen.com/map"

								type="button">Go to Open Mic/Showcase Map
							</Button>
						</Grid.Col>


						<Grid.Col span={2}>
							<Button size='xl'
								color="grape"
								component='a'
								href="https://app.getcomedyseen.com/upcoming"

								

								type="button">Search by City
							</Button>
						</Grid.Col>
						



					</Grid>
				</Center>

			</Container>
			<Space h='md' />

			<Divider sx={{ color: theme.colors.gray[5] }} />



			<Center>

				<Grid gutter={4} gutterMd="xl" grow>
					<Grid.Col span={2}>
						<Image src={twophone} fit='contain' maw={700} mx="auto"></Image>
					</Grid.Col>

					<Grid.Col span={2}>
						<Image src={laptop} fit='contain' maw={700} mx="auto"></Image>
					</Grid.Col>

				</Grid>

			</Center>











			<br />


			<Center>
				<Badge color='grape' size='xl'>Your companion to the stand up world!</Badge>

			</Center>












			<br />

			<br />
			<br />

			<Container>

				<SimpleGrid cols={2}>
					<Anchor href="https://apps.apple.com/us/app/pobblebonk-app/id1606669167">
						<Image src={apple} maw={400} mx='auto' fit='cover' />
					</Anchor>
					<Anchor href="https://play.google.com/store/apps/details?id=com.pobblebonk.pobblebonk">
						<Image src={android} maw={450} mx='auto' fit='cover' />
					</Anchor>

				</SimpleGrid>

			</Container>


			<br />
			<br />









			<br />



			<Center>
				<h1>Features</h1>
			</Center>




			<Tabs defaultValue="Comedians" color="green" variant='pills'>
				<Tabs.List position="center" grow>
					<Tabs.Tab sx={{fontSize:'1.5rem'}} value="Fans" >Fans</Tabs.Tab>
					<Tabs.Tab sx={{fontSize:'1.5rem'}}value="Comedians">Comedians</Tabs.Tab>
					<Tabs.Tab sx={{fontSize:'1.5rem'}}value="Sponsors">Sponsors</Tabs.Tab>
				</Tabs.List>

				<Tabs.Panel value="Fans" pt="xs">


					<SimpleGrid
						cols={2}
						spacing="xl"
						color='red'
						breakpoints={[
							{ maxWidth: '48rem', cols: 2, spacing: 'sm' },
							{ maxWidth: '36rem', cols: 1, spacing: 'sm' }

						]}>

						{fan_cards.map((card, index) => (



							<div color='red'>
								<Card sx={{ background: "#d9ebf2" }} shadow="sm" padding="lg" radius='md' withBorder>
									<Text fz="lg">{card.title}</Text>
									<Text>{card.content}</Text>

								</Card>
							</div>

						)


						)}

					</SimpleGrid>



				</Tabs.Panel>
				<Tabs.Panel value="Comedians">


					<SimpleGrid
						cols={2}
						spacing="xl"
						breakpoints={[
							{ maxWidth: '48rem', cols: 2, spacing: 'sm' },
							{ maxWidth: '36rem', cols: 1, spacing: 'sm' }

						]}>

						{comedian_cards.map((card, index) => (



							<div>
								<Card sx={{ background: "#d9ebf2" }} shadow="sm" padding="lg" radius='md' withBorder>
									<Text fz="lg">{card.title}</Text>
									<Text>{card.content}</Text>

								</Card>
							</div>

						)


						)}

					</SimpleGrid>


				</Tabs.Panel>


				<Tabs.Panel value="Sponsors">

					<SimpleGrid
						cols={2}
						spacing="xl"
						breakpoints={[
							{ maxWidth: '48rem', cols: 2, spacing: 'sm' },
							{ maxWidth: '36rem', cols: 1, spacing: 'sm' }

						]}>

						{sponsor_cards.map((card, index) => (



							<div>
								<Card sx={{ background: "#d9ebf2" }} shadow="xl" padding="lg" radius='lg' withBorder>
									<Text fz="lg">{card.title}</Text>
									<Text>{card.content}</Text>

								</Card>
							</div>

						)


						)}

					</SimpleGrid>



				</Tabs.Panel>




			</Tabs>





			<Footer links={links} />







		</body>








	);

}

export default Landing;
