import '../App.css'
import logo from '../PB001b_ComedySeen_090723_logo_2.jpg'
import { Center, Image, Text, Container, Space } from '@mantine/core'
import Footer from '../components/Footer';
import profile from '../290591517_10109352142199085_172794176054360776_n.jpg'


const DeleteAccount = () => {


return (

<body>

<Text ta='center' sx={{fontSize: "2rem"}}>Want to delete your account?</Text>


<Container>

    <pre>

        {`


If you want to delete your Comedy Seen account and the data associated with it, just follow the following steps in the app:



1.  From the home tab click on "Update Profile"

2. Scroll down and click on "Delete Account"


That should do it!


If you have any other questions or run into any issues just email pete@pobblebonklive.com


`}

</pre>

</Container>

</body>




)




}


export default DeleteAccount