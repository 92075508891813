import '../App.css'
import logo from '../PB001b_ComedySeen_090723_logo_2.jpg'
import { Center, Image, Text, Container, Space } from '@mantine/core'
import Footer from '../components/Footer';
import profile from '../290591517_10109352142199085_172794176054360776_n.jpg'

function Blog() {


	const links = [


		{
			"link": "/About",
			"label": "About"

		},
		{
			"link": "/Support",
			"label": "Support"

		},
		{
			"link": "/Pp",
			"label": "Privacy"

		},
		{
			"link": "/Tos",
			"label": "Terms of Use"

		},

	]

	return (

		<body>

			<Text ta='center' sx={{ fontSize: "2rem" }}>2027</Text>

			<Space h='md' />

			<Text ta='center'>11/14/23</Text>

			<Container>

				<pre>

					{`

Comedy Seen will have guaranteed support until the end of 2027 (unless one of you shanks me at an open mic).  Starting in 2026 there may be some cost rationalization.  Of course the goal is that this runs forever and the only way that can be possible is if the costs match the revenues.  Revenues of which there are currently zero with costs climbing but you all know the dual goals:  1) Sponsorship money - which is shared with comedians through the leaderboard as a new source of revenue for comedians and 2) Ticket sales - This will be done at a cheaper fee than alternatives making it easier for comedians to make money.  I will be going for revenues in 2024.  

I keep thinking of ideas and they really need to be given a fair shake. After thinking it through, 2027 is the magic year where we can safely say we've tried it all, left no stone unturned and gave everything a fighting chance.

In the latest update I've included the revamped Joke Book.  This is something that I will use as I get back to doing open mics.  If you're truly an OG follower you know that we've had a place to keep your jokes in the app since the beginning.  Perhaps even earlier than the check-in functionality.  But no one used it.  The truth death knell was that even I didn't use it.  I found using Google Docs more helpful for working on and keeping jokes because I like to type them out on a keyboard as opposed to a phone.  So I took it out of the app.  Fast forward to today and we have a web version of the app available and it seems to work just as well for my use case!  This was kind of a surprise to me when I created the web version, so it's kind of a welcome surprise. We don't hold your data hostage.  You can have all your jokes emailed to you by just clicking a button and it's all free!

Please check it out and remember any new bug reports get one point on the leaderboard.


Thanks everyone!

-Pete

	`}

				</pre>
			</Container>

			<Text ta='center' sx={{ fontSize: "2rem" }}>Comedy Cash</Text>

			<Space h='md' />

			<Text ta='center'>9/19/23</Text>

			<Container>

				<pre>

					{`

If you have been following along the "Comedy Seen" name was chosen through voting from followers on our IG.  We had 16 candidate names that were voted on bracket style.  "Comedy Cash" was kicked out of the running for the app name for lack of votes, but I think it's important to get across that $ is central to the app.  Sure you can get bragging rights, but I think its cool that a comic gets a few hundred dollars right before end of year holidays.  It's not going to change anyone's life but it's like a gift that comes with a credit and you don't have to do anything different other than hitting the check-in button to participate.  And obviously if the app grows the prizes grow.  That's the model.

To further demonstrate that model, we'll be adding $300 in prize money to the 2023 Brooklyn local leaderboard for comics who have been performing in Brooklyn for the majority of the year.  $100 to first, $100 to second, and $100 to third.  These prizes stack with global prizes.  Brooklyn has the biggest user base, the most check-ins and it just has...potential.  It's largely because of @alexmakesjokes and @maxkaczor (who I had the pleasure of getting dinner with in NYC a couple weeks ago!).  Just look at the open mic map, it's a legitimate resource in that city. Brooklyn will also get a sponsored leaderboard for 2024 as well and I will try to recoup that money with sponsors, most likely Brooklyn based.

To attempt to not make this only Brooklyn based, we're adding a 4th prize of $100 to the global leaderboard.  I want more cities involved.  This model is scalable and I would like to add prize money to another city next year but it has to show some traction like Brooklyn does.  So if you want prize money to come to your city, compete on the global leaderboard!
            
Can this all end?  Absolutely, if it doesn't grow and people don't check-in.  Maybe the idea of comics making some extra money is just too foreign and obscene.  I don't know.  lol.  Then this just goes into the graveyard of apps and websites that have tried to make something work in stand up comedy (I can name 4 off the top of my head).  The titles and cash can all be stripped away and frankly SHOULD under the right circumstances.  Then everything reverts back to the status quo.  Which is still great, I love stand up just like you love stand up and will continue to.  I taught myself to code with this and startup experience is pretty valuable in any venture in the future for me.  But no reversions until the plan has been given a chance.  There are too many ideas left (like selling tickets!), things to learn, and fun to be had trying to make this work.  No plan B until 2025 at the earliest.  

If we can pull this off including selling tickets -- we will have made a new source of revenue/exposure for comedians that is completely additive to what they already do and also made it cheaper for comedians to sell tickets.  This impact can be significant and sustainable because of the app's low cost structure (solopreneur-bootstrapped-side project).  And if costs do get out of control yet a good amount still wants to see it continue, I've had many talks with people in the venture capital community both via networking and on a personal level and we can go down that road.  It's a different ballgame that I would argue doesn't allow the app to benefit the community as much in its intended form, but if necessary we can play it.  And it's not the worst thing to be venture backed.

So the future of this is really up to you all so check-in if you want this to stay!


-Pete

                `}

				</pre>
			</Container>


			<Footer links={links} />


		</body>




	)



}


export default Blog