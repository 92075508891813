import React from 'react';
import logo from './logo.svg';
import './App.css';
import { MantineProvider, Text } from '@mantine/core';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Landing from './pages/Landing'
import Pp from './pages/Pp'
import Tos from './pages/Tos'
import Support from './pages/Support'
import About from './pages/About'
import HOF from './pages/HOF'
import Blog from './pages/Blog'
import Blog2 from './pages/Blog2'
import LeaderboardPrizes24 from './pages/LeaderboardPrizes24';
import Promotion23 from './pages/Promotion23';
import Promotion24 from './pages/Promotion24';
import DeleteAccount from './pages/DeleteAccount';
import { inject } from '@vercel/analytics'



function App() {

	inject()



  return (
    <MantineProvider 
    theme={{





      

      globalStyles: (theme) => ({

        body: {
          backgroundColor: "#d9ebf2"


        },
  

  
        }
        ),


    }}

      
      >
	    <Routes>
        <Route path='/' element={<Landing/>}/>
        <Route path='/Pp' element={<Pp/>}/>
        <Route path='/Tos' element={<Tos/>} />
        <Route path='/Support' element={<Support/>} />
        <Route path='/About' element={<About/>} />
        <Route path='/HOF' element={<HOF/>} />
        <Route path='/Blog' element={<Blog/>} />
	<Route path='/Blog2' element={<Blog2/>} /> 
        <Route path='/LeaderboardPrizes24' element={<LeaderboardPrizes24/>} />
	<Route path='/Promotion23' element={<Promotion23/>} />
		    <Route path='/Promotion24' element={<Promotion24/>} />
  <Route path='/DeleteAccount' element={<DeleteAccount/>} />




	    </Routes>
    </MantineProvider>
    
  );
}

export default App;
