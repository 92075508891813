import '../App.css'
import logo from '../PB001b_ComedySeen_090723_logo_2.jpg'
import { Center, Image, Text, Container, Space, Grid, SimpleGrid } from '@mantine/core'
import Footer from '../components/Footer';
import profile from '../290591517_10109352142199085_172794176054360776_n.jpg';
import { IconCrown, IconStars, IconStar } from '@tabler/icons-react';

function HOF() {


    const links = [


        {
            "link": "/About",
            "label": "About"

        },
        {
            "link": "/Support",
            "label": "Support"

        },
        {
            "link": "/Pp",
            "label": "Privacy"

        },
        {
            "link": "/Tos",
            "label": "Terms of Use"

        },

    ]


    return (

        <body>
            <Center>

                <Text ta='center' sx={{ fontSize: '3rem' }} className='HOF'>  Hall Of Fame  </Text>


            </Center>


            <Text ta='center' sx={{ fontSize: '2rem' }}> 2023</Text>
            <Text ta='center' sx={{ fontSize: '1rem' }}>(1st year known as "Comedy Seen")</Text>




            <Space h='md' />









            <Container>


                <SimpleGrid cols={2}>
                    <div>
                        <Center>
                            <IconCrown
                                size={50}
                                strokeWidth={2}
                                color={'#532d86'} /><Text ta='center' fw={700}>World's Top Comic: Alex Shklar, Brooklyn, NY, USA</Text>
                        </Center>
                        <Text ta='center'>2nd: Max Kaczor, Brooklyn, NY, USA</Text>
                        <Text ta='center'>3rd: Cody Z, Buffalo, NY, USA</Text>

                        <Space h='md' />

                        <Center>
                            <IconCrown
                                size={25}
                                strokeWidth={2}
                                color={'#40bfbf'} />

                            <Text ta='center' fw={700}>Hardest Working Comic (Pobblebonk Award): Alex Shklar, Brooklyn, NY, USA</Text>

                        </Center>

                        <Space h='md' />

                        <Center>
                            <IconCrown
                                size={25}
                                strokeWidth={2}
                                color={'#40bfbf'} />

                            <Text ta='center' fw={700}>Top Comic - Brooklyn: Alex Shklar, Brooklyn, NY, USA</Text>

                        </Center>
                        <Text ta='center'>2nd: Max Kaczor, Brooklyn, NY, USA</Text>
                        <Text ta='center'>3rd: Jose Bedoy, Brooklyn, NY, USA</Text>


                    </div>
                    <div>
             

                    <Center>

<IconCrown
    size={50}
    strokeWidth={2}
    color={'#4dbf40'} />
<Text ta='center' fw={700}>World's Top Fan: Evan Daniels, Brooklyn, NY, USA</Text>
</Center>
<Text ta='center'>2nd: Leandro Roldan, Miami, FL, USA</Text>
<Text ta='center'>3rd: Cristina Sarria, Nashville, TN, USA</Text>

<Space h='md' />

                        <Center>

                            

                            <IconCrown
                                size={25}
                                strokeWidth={2}
                                color={'#4dbf40'} />
                            <Text ta='center' fw={700}>World's Top Mic: The FUN Mic with Alex Shklar, Brooklyn, NY, USA host: Alex Shklar</Text>
                        </Center>
                        <Text ta='center'>2nd: Late Night Mic (Noah Miller), Brooklyn, NY, USA contributor: Alex Shklar</Text>
                        <Text ta='center'>3rd: Freddy's Wednesday Mic, Brooklyn, NY, USA contributor: Alex Shklar</Text>

                    </div>


                </SimpleGrid>
            </Container>

            <Text ta='center' sx={{ fontSize: '2rem' }}> 2022</Text>
            <Text ta='center' sx={{ fontSize: '1rem' }}>(app was known as "Pobblebonk")</Text>




            <Space h='md' />









            <Container>


                <SimpleGrid cols={2}>
                    <div>
                        <Center>
                            <IconCrown
                                size={50}
                                strokeWidth={2}
                                color={'#532d86'} /><Text ta='center' fw={700}>World's Top Comic: Alex Shklar, Brooklyn, NY, USA</Text>
                        </Center>
                        <Text ta='center'>2nd: Cody Z, Orlando, FL, USA</Text>
                        <Text ta='center'>3rd: Max Kaczor, Buffalo, NY, USA</Text>

                        <Space h='md' />

                        <Center>
                            <IconCrown
                                size={25}
                                strokeWidth={2}
                                color={'#40bfbf'} />

                            <Text ta='center' fw={700}>Hardest Working Comic (Pobblebonk Award): Cody Z, Orlando, FL, USA</Text>

                        </Center>
                    </div>
                    <div>

                        <Center>

                            <IconCrown
                                size={50}
                                strokeWidth={2}
                                color={'#4dbf40'} />
                            <Text ta='center' fw={700}>World's Top Mic: The FUN Mic with Alex Shklar, Brooklyn, NY, USA host: Alex Shklar</Text>
                        </Center>
                        <Text ta='center'>2nd: Monday Night Comedy Mic at Grumpy's, Orlando, FL, USA host: Ike Rafferty</Text>
                        <Text ta='center'>3rd: Dane Curley's Underground Comedy, Brooklyn, NY, USA contributor: Alex Shklar </Text>


                      



                    </div>


                </SimpleGrid>
            </Container>


            <Footer links={links} />



        </body>
    )



}


export default HOF