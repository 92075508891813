import '../App.css'
import logo from '../comedy_seen_transparent.png'
import {Center, Image, Text} from '@mantine/core'
import Footer from '../components/Footer';



function Support() {

    const links = [
		

		{
			"link": "/About",
			"label": "About"

		},
		{
			"link": "/Support",
			"label": "Support"

		},
		{
			"link": "/Pp",
			"label": "Privacy"

		},
		{
			"link": "/Tos",
			"label": "Terms of Use"

		},

	]


    return (
<body>

<Center>
<Image src={logo} fit='contain' maw={500} mx='auto'></Image>
</Center>

<br/>
<br/>

<Text ta='center'>Contact and Support</Text>
<Text ta='center'>DM us on our socials or email support@pobblebonklive.com</Text>



<Footer links={links}/>




</body>

    )
}


export default Support;