import { createStyles, Container, Group, Anchor, rem, Image } from '@mantine/core';
import { MantineLogo } from '@mantine/ds';
import { Link } from 'react-router-dom';
import logo from '../PB001b_ComedySeen_090723_logo(1).png'


const useStyles = createStyles((theme) => ({
    footer: {
      marginTop: rem(120),
      borderTop: `${rem(1)} solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[5]
      }`,
    },
  
    inner: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: theme.spacing.xl,
      paddingBottom: theme.spacing.xl,
  
      [theme.fn.smallerThan('xs')]: {
        flexDirection: 'column',
      },
    },
  
    links: {
      [theme.fn.smallerThan('xs')]: {
        marginTop: theme.spacing.md,
      },
    },
  }));
  
  interface FooterSimpleProps {
    links: { link: string; label: string }[];
  }
  
  export default function Footer({ links }: FooterSimpleProps) {
    const { classes } = useStyles();
    const items = links.map((link) => (
      <Anchor
        color="dimmed"
        key={link.label}
        href={link.link}
        //onClick={(event) => event.preventDefault()}
        size="sm"
      >
        <Link to={link.link}>{link.label}</Link>
      </Anchor>
    ));
  
    return (
      <div className={classes.footer}>
        <Container className={classes.inner}>
          <Anchor href="/">
          <Image src={logo} fit="contain" width={100} />
          </Anchor>
          <Group className={classes.links}>{items}</Group>
        </Container>
      </div>
    );
  }
