import '../App.css'
import logo from '../PB001b_ComedySeen_090723_logo_2.jpg'
import { Center, Image, Text, Container, Space } from '@mantine/core'
import Footer from '../components/Footer';
import profile from '../290591517_10109352142199085_172794176054360776_n.jpg'

function Blog2() {


    const links = [


		{
			"link": "/About",
			"label": "About"

		},
		{
			"link": "/Support",
			"label": "Support"

		},
		{
			"link": "/Pp",
			"label": "Privacy"

		},
		{
			"link": "/Tos",
			"label": "Terms of Use"

		},

	]

    return (

        <body>

            <Text ta='center' sx={{fontSize: "2rem"}}>2027</Text>

            <Space h='md' />

            <Text ta='center'>11/14/23</Text>

            <Container>

                <pre>

                    {`

Comedy Seen will have guaranteed support until the end of 2027 (unless one of you shanks me at an open mic).  Starting in 2026 there may be some cost rationalization.  Of course the goal is that this runs forever and the only way that can be possible is if the costs match the revenues.  Revenues of which there are currently zero with costs climbing but you all know the dual goals:  1) Sponsorship money - which is shared with comedians through the leaderboard as a new source of revenue for comedians and 2) Ticket sales - This will be done at a cheaper fee than alternatives making it easier for comedians to make money.  I will be going for revenues in 2024.  

I keep thinking of ideas and they really need to be given a fair shake. After thinking it through, 2027 is the magic year where we can safely say we've tried it all, left no stone unturned and gave everything a fighting chance.

In the latest update I've included the revamped Joke Book.  This is something that I will use as I get back to doing open mics.  If you're truly an OG follower you know that we've had a place to keep your jokes in the app since the beginning.  Perhaps even earlier than the check-in functionality.  But no one used it.  The truth death knell was that even I didn't use it.  I found using Google Docs more helpful for working on and keeping jokes because I like to type them out on a keyboard as opposed to a phone.  So I took it out of the app.  Fast forward to today and we have a web version of the app available and it seems to work just as well for my use case!  This was kind of a surprise to me when I created the web version, so it's kind of a welcome surprise. We don't hold your data hostage.  You can have all your jokes emailed to you by just clicking a button and it's all free!

Please check it out and remember any new bug reports get one point on the leaderboard.


Thanks everyone!

-Pete

                `}

                </pre>
            </Container>


            <Footer links={links} />


        </body>




    )



}


export default Blog2
