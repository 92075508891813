import '../App.css'
import logo from '../PB001b_ComedySeen_090723_logo_2.jpg'
import {Center, Image, Text, Container, Space} from '@mantine/core'
import Footer from '../components/Footer';
import profile from '../290591517_10109352142199085_172794176054360776_n.jpg'




function About()  {

    const links = [
		

		{
			"link": "/About",
			"label": "About"

		},
		{
			"link": "/Support",
			"label": "Support"

		},
		{
			"link": "/Pp",
			"label": "Privacy"

		},
		{
			"link": "/Tos",
			"label": "Terms of Use"

		},

	]


return(
    <body>



    <Center>

    <Image src={profile} fit='contain' maw={300} mx='auto'></Image>

    </Center>

    <br/>

    <Container>

    <Text ta='center'>Hi, I'm Pete.  I love stand up and I used to travel a lot more and would sometimes find
    it hard to find information on mics to perform at.  I remember I was performing at a mic in NYC and afterwards all the 
    comics were having a hard time trying to figure out where to perform next. I thought, "How is there not an app for that?"  
	I think that night was really the beginning of Comedy Seen.  Then COVID hit and I decided to teach myself to code as a 
	side project and here we are.  </Text>
   
	<Space h='md' />

	<Text>
        
    Occassionaly you may find me IRL in Miami at an open mic or a show.  Come say "Hi" if you see me!  I am a photographer so you may catch me taking photos as well.  </Text>

    </Container>



    <Footer links={links} />



    </body>
)



}


export default About;